* {
    margin: 0;
    padding: 0;
}

a {
    border-radius: 10px;
    color: aliceblue;
    display: block;
    font-family: 'Courier New', Courier, monospace;
    font-size: 150%;
    margin: 0 5px;
    padding: 10px;
    text-align: center;
    width: 15%;
}

a:hover {
    cursor: pointer;
}

.button-container{
    align-items: center;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.tianren-bday {
    display: block;
    max-width: 80%;
    max-height: 100%;
    margin: auto;
}

.f-purple-button {
    background-color: #D6BCFF;
}

.f-purple-button:hover{
    background-color: #DFD8EA;
}

.f-purple-button:hover:active{
    background-color: #816F9D
}

.purple-button {
    background-color: #6767F2;
}

.purple-button:hover{
    background-color: #B2B2EC;
}

.purple-button:hover:active{
    background-color: #AC99D1
}